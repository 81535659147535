header{
    top: 0;
    position: fixed;
    padding: 0 5%;
    margin: 0;
    height: 50px;
    height: 8%;
    background-color: #ffd8e7;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 2;
  }
  
  .burgerMenu{
    font-size: 25px;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 5px;
    width: fit-content;
    cursor: pointer;
    transition: color 150ms ease-in-out;
    outline: none;
    border: none;
    aspect-ratio: 1/1;
    background-color: #D4A1FE;
    box-shadow: 2px 2px 3px black;
  }

  .burgerMenu:focus{
    outline: none;
    border: none;
  }

  .burgerMenu:hover{
    color: gray;
  }
  
  .elementHide{
    /*display: none;*/
    opacity: 0;
    transition: all 500ms ease-in-out;
  }
  
  .elementShow{
    /*display: inline-block;*/
    opacity: 100%;
  }

  .navTitle{
    font-family: "Slabo", sans-serif;
    margin: 0;
    padding: 0;
    opacity: 100%;
    transition: all 500ms ease-in-out;
  }

  .elementNoDisplay{
    opacity: 0%;
    display: none;
  }

  .selfCenter{
    justify-self: center;
  }
  
  nav{
    position: relative;
    width: 100%;
    margin: 0;
    transition: all 500ms ease-in-out;
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: visible;
}
  
  nav ul{
    /*position: absolute;
    left: 90%;*/
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 35px;
    list-style: none;
    width: fit-content;
    height: fit-content;
  }
  
  .navActive{
    /*position: relative;
    left: 0%;*/
  }

  nav ul li{
    font-family: "Slabo 13px", sans-serif;
    margin: 0;
    padding: 0;
    font-size: 4vw;
    color: black;
    text-decoration: none;
    transition: all 250ms ease-in-out;
    width: fit-content;
    transition: color 10ms ease-in-out;
  }

  .anchorPoint{
    overflow: visible;
    position: relative;
  }
  
  nav ul li:hover{
    cursor: pointer;
    color: rgb(173, 173, 169);
  }
  
  @media screen and (min-width: 600px){
    nav ul li{
      font-size: 25px
    }
  }