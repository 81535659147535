@tailwind base;
@tailwind components;
@tailwind utilities;

.generalPanelGrid{
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    padding: 3% 5%;
    box-sizing: border-box;
}

.generalPanelFlex{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: center;
    padding: 3% 5%;
}

.generalPanelFlexCol{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: center;
    padding: 3% 5%;
}

.generalPanelGrid h2, .generalPanelFlex h2, .generalPanelFlexCol h2{
    font-size: 5vh;
    font-family: "Poetsen One", sans-serif;
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-bottom: 2%;
}

.reviewsPanel{
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5%;
}

.reviewsHeader{
    font-family: "Poetsen One", sans-serif;
    text-align: center;
}

.motivationPanel{
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5%;
}

.squarePic{
    aspect-ratio: 1/1;
}

.motivationPanel .imgContainer{
    width: 65%;
}

.imageHalf{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*Default Panel*/

.panel0 .imageHalf{
    margin-top: 15px;
    order: 1;
}

.panel1 .imageHalf{
    order: -1;
}

.imgContainer{
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.25),
    -5px -5px 10px 0 rgba(234, 234, 234, 0.397);
    background-color: white;
    display: flex;
    align-content: center;
    height: fit-content;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    padding: 2%;
}

.imgMedium{
    width: 75%;
}

.imgContainer img{
    background-color: white;
    width: 100%;
    box-sizing: border-box;
}

.headerAndP{
    margin-left: 0;
    padding: 10%;
    box-sizing: border-box;
}

.headerAndP p{
    font-size: 2.5vh;
    font-size: calc(1.5vw + 1.5vh);
    font-family: Arial, Helvetica, sans-serif ;
    padding: 0;
    margin: 0;
}

.panelDuo, .panelSolo, .panelSoloReviews{
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.qualificationsUl{
    padding: 0;
    margin: 0;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style-type: square;
    list-style-position: inside;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
}

.qualificationsUl li{
    font-weight: 600;
    font-size: calc(1.1vw + 1.1vh);
}

/*Desktop View*/
@media screen and (min-width: 850px){
    .qualificationsUl li{
        font-size: clamp(12px, 1.4vw, 18px);
    }

    .headerAndP h2{
        font-size: 1.8vw;
        font-size: calc(1.8vw * 0.5vh);
        font-size: clamp(20px, 1.8vw, 32px);
    }

    .reviewsHeader{
        font-size: 1.8vw;
        font-size: calc(1.8vw * 0.5vh);
        font-size: clamp(11.5px, 1.3vw, 26px);
    }
    
    .headerAndP p{
        font-size: 1.2vw;
        font-size: clamp(12px, 1.4vw, 18px);
    }

    .generalPanelGrid{
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        width: 65%;
    }

    .panelSolo{
        border-top: 2px dashed black;
    }

    .panelSolo .generalPanelGrid{
        width: 75%;
    }

    .panelDuo{
        flex-direction: row;
        gap: 2%;
    }

    .panelSoloReviews{
        flex-direction: row;
        gap: 2%;
        border-top: 3px solid black;
    }

    .panelSoloReviews .generalPanelGrid{
        padding: 3%
    }

    .panelSoloReviews .reviewsPanel{
        width: 30%;
        order: -1;
    }

    .panelDuo .generalPanelGrid{
        width: 70%;
        border-right: 3px solid black;
    }

    .panelDuo .generalPanelFlex, .panelDuo .generalPanelFlexCol{
        width: 25%;
        box-shadow: 3px 3px 4px black;
        min-height: 100%;
        padding: 0;
    }
}

@media screen and (min-width: 900px){
    .headerAndP h2{
        font-size: 2vw;
    }

    .headerAndP p{
        font-size: 1.4vw;
    }
    
    .qualificationsUl li{
        font-size: 1vw;
    }

}


